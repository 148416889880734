import { Moon, Sun } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Theme, ThemeColors } from '@/types/profit';
import { UserMenu } from './UserMenu';
import { User } from '@supabase/supabase-js';
import { Logo } from '../Logo';

interface HeaderProps {
  colors: ThemeColors;
  theme: Theme;
  toggleTheme: () => void;
  onUpgradeClick: () => void;
  onShowAuth: () => void;
  user: User | null;
  isPro: boolean;
}

export function Header({ 
  colors, 
  theme, 
  toggleTheme, 
  onUpgradeClick, 
  onShowAuth,
  user,
  isPro
}: HeaderProps) {
  return (
    <header 
      className="sticky top-0 z-50 w-full border-b backdrop-blur-sm transition-all duration-200" 
      style={{ 
        backgroundColor: `${colors.background}95`,
        borderColor: colors.muted 
      }}
    >
      <div className="container mx-auto px-4 max-w-[2000px]">
        <nav className="flex h-16 items-center justify-between">
          <div className="flex items-center gap-3">
            <Logo colors={colors} />
          </div>

          <div className="flex items-center gap-4">
            {user ? (
              <UserMenu 
                customColors={colors} 
                user={user}
                isPro={isPro}
                onUpgradeClick={onUpgradeClick}
              />
            ) : (
              <>
                <Button 
                  variant="ghost"
                  onClick={onShowAuth}
                  style={{ 
                    color: colors.text,
                    backgroundColor: `${colors.primary}10`
                  }}
                >
                  Sign In
                </Button>
                <Button 
                  variant="premium"
                  className="hidden sm:flex items-center gap-2"
                  onClick={onUpgradeClick}
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.background
                  }}
                >
                  <span className="hidden sm:inline">Upgrade to</span> Pro
                </Button>
              </>
            )}
            <Button 
              variant="outline"
              size="icon"
              onClick={toggleTheme}
              className="border-0 p-2"
              style={{ 
              color: colors.text,
              backgroundColor: `${colors.primary}10`
              }}
            >
              {theme === "light" ? <Moon className="h-6 w-6" /> : <Sun className="h-6 w-6" />}
            </Button>
          </div>
        </nav>
      </div>
    </header>
  );
}